$font-family-Poppins: Poppins, Helvetica, sans-serif !important;
$font-family-PoppinsSemiBold: PoppinsSemiBold, Helvetica, sans-serif !important;
// $font-family-Poppins: Poppins !important;
$font-family-PoppinsBold: PoppinsBold, Helvetica, sans-serif !important;
$font-family-Inter: Inter, Helvetica, sans-serif !important;
$font-family-InterBold: InterBold, Helvetica, sans-serif !important;
$font-family-InterSemiBold: InterSemiBold, Helvetica, sans-serif !important;
$font-family-InterMedium: InterMedium, Helvetica, sans-serif !important;
$font-family-PoppinsMedium: PoppinsMedium, Helvetica, sans-serif !important;
$forn-family-PoppinsOnly: Poppins !important;
$font-family-GraphikRegular: GraphikRegular, Helvetica, sans-serif !important;
$font-family-GraphikBold: GraphikBold, Helvetica, sans-serif !important;
$bs-strong-black-100: #000000;
$neutral-black-100: #1F1F1F;
$strong-black-75: #4D4D4D;
$strong-black-60: #646464;

.wideContainer {
  div.addPaymentMethodBox {
    .addpaymentLink {
      font-family: $font-family-Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      /* 150% */
      text-decoration-line: underline;
      padding-left: 24px;
      background: url("../images/addpayment/addpaymentIcon.png") no-repeat center left;
    }
  }
}

.text-color-primary {
  color: $bs-strong-black-100!important;
}
.text-color-neutral {
  color: $neutral-black-100!important;
}
.text-color-secondary {
  color: $strong-black-75!important;
}
.text-color-black-60 {
  color: $strong-black-60!important;
}
.font-primary {
  font-family: $font-family-Poppins;
}
.font-secondary {
  font-family: $font-family-Inter;  
}
.text-spacing {
  letter-spacing: normal!important;
}

.wideContainer {

  .tab {
    min-height: 33px;
    font-family: $font-family-Poppins;
    justify-content: flex-start;
    font-weight: 500!important;
    padding: 0!important;
    @media screen and (max-width: 767.9px) {
      font-size: 12px;
      line-height: 16px;
    }    
  }
  .MuiTabs-root {
    min-height: 33px;
  }

  .suspendedPayBalanceDue,
  .paymentContainer .expiration {
    margin-left: 170px;

    @media screen and (max-width: 1023.9px) {
      margin-left: 0px;
      margin-top: 32px;
    }
  }

  .paymentCard {
    .header {
      display: flex;
      gap: 10px;
      align-items: self-start;     
    }

    button.MuiButtonBase-root.MuiIconButton-root {
      margin-left: 10px;
      padding-bottom: 16px;
    }
  }
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 3;
}

div.acp-Modal-Container {
  padding: 64px 40px 48px 40px;

  @media screen and (max-width: 767.9px) {
    padding: 64px 16px 48px 16px;
  }

  .bill_content {
    h2 {
      font-family: $font-family-Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      margin: 0px;
    }

    p.step_one {
      margin: 16px 0px 0px 0px;
    }

    p.step_two {
      margin: 40px 0px 0px 0px;

    }

    p.step_one,
    p.step_two {
      b {
        font-family: $font-family-Poppins;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
      }

      p {
        font-family: $font-family-Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    button.modal-button {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      margin: 24px 0px 0px 0px;

      @media screen and (max-width: 767.9px) {
        width: 100%;
      }

      &:hover {
        background: #000000;
        color: #ffffff;
      }

      span {
        font-family: $font-family-Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
    }

    a.help {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
      text-decoration-line: underline;
    }
  }
}

.acp_form_fields {
  .title {
    font-family: $font-family-Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }

  .acp-typography {
    label.radio-button-label {
      font-family: $font-family-Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

button.updatePausePopupBtn {
  display: flex;
  align-self: flex-start;
  margin: 24px 0 0 0 !important;
  box-shadow: none !important;
  font-family: $font-family-Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 16px;
  background-color: #ffc800;
  height: 48px;
  width: 240px;
}

.updatePausePopupBtn:hover {
  background-color: #000000 !important;
  color: #ffffff;
}

/* Forget Password Css changs */
.forget-pass-radioGroup {
  margin-top: 40px;
}

.css-61q0b2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 571px !important;
}

.dropdownMultiAccounts {

  width: 550px;
  height: 40px;
  margin: 0px !important;
  @media screen and (max-width:767.9px) {
    width: 100%!important;   
  }

  .MuiInputBase-input {
    border: 1px solid $bs-strong-black-100;
    padding: 8px 16px;
    border-radius: 0px;
  }
  .multi-account-select {
    border-radius: 0px;
    .MuiSelect-select {
      border-radius: 0;
    }
    @media screen and (max-width:767.9px) {
      width: 100%!important;   
    }
  }
}
.manage-account-upwardArrowIcon{
  transform: rotate(180deg);
}
.manage-account-MenuListOpen{
  
}
.upcoming-charges-inside-lables{
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;

}
.viewpayments-lable-amount{
  font-family: $forn-family-PoppinsOnly;
}


.viewpayments-view-detailsP{
  color: var(--strong-black-75, #4D4D4D) !important;
 

  /* Body Text/X-Small */
  font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;  /* 133.333% */
}
.viewpayments{
  color: var(--strong-black-75, #4D4D4D) !important; 
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 21px !important; /* 150% */
}
.viewpayments-monthly{
  color: var(--strong-black-75, #4D4D4D) !important; 
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important; /* 150% */
}
.tax-not{
  font-size: 12px !important;
  line-height: 16px !important;
}
.view-bill-accrodion{
  border-top: 1px solid #7F7F7F;
  margin-top: 28px;
  box-shadow: none !important; 
  padding: 20px 0 0 !important; 
  &::before {
    content: none!important;
  }
  .MuiAccordionDetails-root {
    padding: 10px 0 0;
  }
  .title {
    margin-bottom: 0!important;
  }
  @media screen and (min-width:1366px) {
    width: 674px;
  }
}

.autoPayStatus {
  display: flex;
  padding: 48px 24px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 20px; */
  align-self: stretch;

  .title {
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
  }

  .subtitle {
    font-family: $font-family-Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 32px;
    color: $bs-strong-black-100;
  }

  .divider {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .autoPayInfo {
    font-family: Inter !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
    letter-spacing: normal;
  }
}
.manageAutoPay{
  .heading{
    color:$bs-strong-black-100;
    font-family: $font-family-Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .enrolPay{
    margin-top: 32px;
    color:$bs-strong-black-100;
    font-family:$font-family-Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .footerBtns.MuiBox-root{
    gap: 24px;
    display: flex;
    margin-top: 40px;
    @media screen and (max-width: 767.9px) {
      flex-direction: column-reverse;
    }
    button.MuiButtonBase-root{
      margin: 0px;
      cursor: pointer;
      @media screen and (max-width: 767.9px) {
        width: 100%!important;
      }
      &:hover{
        background: #000;
        color: #ffffff;
      }
      &:last-child{
        margin-left: 0px;
      }
    }
    button.MuiButton-containedPrimary{
      //background: #FFC800;
      margin: 0px!important;
      cursor: pointer;
      &:hover{
        cursor: pointer;
        background: #000;
        color: #ffffff;
      }
    }
    button.MuiButton-containedSecondary{
      background: none;
      border: 2px solid $bs-strong-black-100;
    }
    button.Mui-disabled{
      background: #FFF1BF;
    }
  }
  .radio-button-container{
    display: flex;
    gap: 32px;
    margin: 16px 0;
    .radio-button-label{
      padding: 0;
      font-family: $font-family-Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      color: $bs-strong-black-100;
      display: flex;
      align-items: center;
      width: fit-content;
      gap: 8px;
      cursor: pointer;
      span{
        padding: 0;
        svg{
          width: 21px;
          height: 21px;
        }
      }
    }
  }
  .paymentMethodLink.MuiBox-root{
    display: flex;
    /* margin: 24px 0px 0px 0px; */
    gap: 8px;
    cursor: pointer;
    p{
      color:$bs-strong-black-100;
      font-family: $font-family-Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      text-decoration: underline;
      /* text-decoration-thickness: 1.5px; */
      text-underline-offset: 2px;
    }
    svg{
      width: 21px;
      height: 21px;
    }
  }
  .autopayNote {
    p{
      &.setPaperless {
      color:$bs-strong-black-100;
      font-family: $font-family-Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-top: 32px;
      }
    }
  }
  .autopayNote{
    p {
      &.paperlessbill {
      color: #646464;
    font-family: Inter, Helvetica, sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 5px;
    }
  }
  }
  .autopayNote{
  p{
      color:$bs-strong-black-100;
      font-family: $font-family-Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-top: 15px;
    }
label.checkboxMain{
      padding-left: 24px!important;
    }
  }
  .termsCondition{
  input{
      margin-right: 8px;
    }
    a{
      margin-left: 5px;
    }
    span,a{
      color:$bs-strong-black-100;
      font-family: $font-family-Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      cursor: pointer;
    }
    div{
      color: #646464;
      font-family: $font-family-Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      margin-top: 16px;
    }
  }
  .form-control-container{
    margin-top: 24px;
    .paymentMethodsTitle{
      color: $bs-strong-black-100;
      font-family: $font-family-Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
  .payment-group-container-Main{
    /* margin-top: 24px!important; */
    .paymentLabel{
      color:#4D4D4D !important;
      font-family: $font-family-Inter;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      margin-top: 0px;
    }
    .payment-group-container{
      display: flex;
      padding-left: 12px;
      line-height: initial;
      .payment-details{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        .payment-date-year{
          display: flex;
          align-items: center;
          .expiration-year{
            margin-left: 24px;
          }
          .card-number{
            .MuiRadio-colorPrimary{
              margin: 0;
              padding: 0;
            }
            .card-number-label{
              margin-left: 8px;
            }
            input,svg{
              height: 21px;
              width: 21px;
            }
          }
          label{
            color:$bs-strong-black-100;
            font-family: $font-family-Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */
          }
        }
        label{
          color:$bs-strong-black-100;
          font-family: $font-family-Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px; /* 150% */
        }
      }
      .edit-payment-group{
        display: flex;
        align-items: center;
        // margin-left: 56px;
        label,
        .edit-payment {
          margin-right: 12px;
          color:$bs-strong-black-100;
          font-family: $font-family-Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px; /* 150% */
          text-decoration-line: underline;
          position: relative;
          cursor: pointer;
          // &::after{
          //   content: '';
          //   width: 0;
          //   height: 100%;
          //   position: absolute;
          //   border: 1px solid black;
          //   top: 0;
          //   left: 32px;
          // }
        }
        .trashIcon{
          background: url("../images/trash.png") center no-repeat;
          width:36px;
          height: 16px;
          cursor: pointer;
          padding-left: 12px;
          border-left: 1px solid #000;
        }
      }
    }
  }
}
.delete-autopay-container {
  display: flex;
  width: 616px;
  padding: 64px 40px 48px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  .header-container {
    display: flex;
    width: 535px;
    padding-bottom: 0px;
    align-items: flex-start;
    flex-direction: column;
    hr {
      width: 100%;
      height: 2px;
      margin-top: 24px;
    }

    .title {
      color: var(--strong-black-100, #000);
     
      font-family: Poppins;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;

      /* 142.105% */
    }

    // border-bottom: 1px solid var(--strong-black-100, #000);
  }

  .delete-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    .delete-message {
      display: flex;
      flex-direction: column;
      padding-bottom: 12px;
      gap: 20px;
    }

    .payment-method-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      .title {
        color: var(--strong-black-100, #000);       
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 150% */
      }

      .radio-button-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        .radio-button-list {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
        }
      }
    }
  }
}

.disableAutoPayment {
  display: flex;
  // width: 616px;
  padding: 64px 40px 48px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;

  .title {
    width: 536px;
    color: var(--strong-black-100, #000);
   

    /* Headings/Heading Level 3 */
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    /* 128.571% */
  }

  .message {
    color: #000;
  
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    /* 150% */
  }

}
.required-lable{
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  color: var(--strong-black-50, #7F7F7F);


  /* Forms/Label small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
.confirmPage{
  display: flex;
  // gap: 24px;
  flex-direction: column;
  align-items: center; 
  @media screen and (min-width:1280px) {
    width: 968px;
  }
  @media screen and (min-width:768px) and (max-width:1023.9px) {
    width: 696px;
  }
  @media screen and (min-width:1024px) and (max-width:1279.9px) {
    width: 776px;
  }
  @media screen and (max-width:767.9px) {
    align-items: flex-start; 
  }
}

.creditCardImages {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0px;

  .singleCardImage {
    // width: 56px;
    height: 40px;
  }
}

.addNewPaymentMethod.addNewPaymentMethodAutopay {
  .MuiFormControl-root{
    margin: 0px;
  }
  hr.MuiDivider-root {
    @media screen and (max-width: 767.9px) {
      margin-left:0px;
      margin-right:0px;
    }
  }
  .paymentMethodButtons {
    @media screen and (max-width: 767.9px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 24px;
    }
    button{
      &:last-child{
        @media screen and (max-width: 767.9px) {
          margin-top: 0px;

        }
      }
    }

  }
  .paymentMethodHeading{
    margin-top: 48px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    // margin-left:20px;
    // margin-right:20px;
    // @media screen and (max-width: 767.9px) {
    //   margin-left:0px;
    //   margin-right:0px;
    // }
    .clearLabel {
      color: #808080;
    }
  }
  .cityZipcode,.expireCvvDate,.creditCardInput,.fullNameInput,.bankAccountInput, .addressOne,.bankAccNameInput{
    margin-top: 20px!important;
    .inputFiledErrorImg.imageError{
      margin-bottom: 0px;

    }
    .routingErrorPoints{
      padding-left: 22px;;
    }
    .inputNormalBorder{ border: solid 1px black;}
    .inputErrorBorder {
      border: solid 1px #d11e00;
    }
  }
  .requiredLabel {
    color: #7F7F7F;
    font-family: $font-family-Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
  }
  .checkBox.primarycheckbox{
    padding: 32px 0;
    label.makePrimaryPaymentCheckbox{
      color: #000;
      font-family: $font-family-Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      /* 150% */
    }
  }
}

.stateZipCode.autoPay {
  .stateInput {
    position: relative;

    .errorMsgFormat.autoPay {
      position: absolute;
      bottom: -20px;
    
    }
  }
}

.MuiInput-root .MuiSelect-select,
.MuiMenu-list li,
.MuiInputBase-root .MuiInputBase-input {
  font-family: $font-family-Inter;
  color: #000000!important;
}

.addNewPaymentMethod,.addNewPaymentMethod.addNewPaymentMethodAutopay {
  .MuiFormControl-root{
    margin: 0px;
  }
  .closeIcon{
    position: absolute;
    top:24px;
    right: 24px;
    cursor: pointer;
  }
  hr.MuiDivider-root {
    @media screen and (max-width: 767.9px) {
      margin-left:0px;
      margin-right:0px;
    }
  }
  .paymentMethodButtons {
    @media screen and (max-width: 767.9px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 24px;
    }
    button{
      &:last-child{
        @media screen and (max-width: 767.9px) {
          margin-top: 0px;

        }
      }
    }

  }
  .paymentMethodHeading{
    margin-top: 0px;
    display:flex;
    justify-content:space-between;
    align-items:center;  
  }
  .fullNameInput {
    margin-top: 16px;
  }
  .cityZipcode,.expireCvvDate,.creditCardInput,.bankAccountInput{
    // margin-top: 1.5rem;
    margin-top: 24px!important;
  }
  .cityZipcode {
    input[type=text]{
      padding-left: 0.5rem;
      padding-right: 0.5rem; 
    }
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
  .requiredLabel {
    color: #7F7F7F;
    font-family: $font-family-Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 16px;
  }
  .checkBox.primarycheckbox{
    padding: 32px 0;
    label.makePrimaryPaymentCheckbox{
      color: #000;
      font-family: $font-family-Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      /* 150% */
    }
  }

  input:disabled, 
  select:disabled,
  .Mui-disabled ,
  .Mui-disabled.MuiSelect-select{
    background-color: #F2F3F3;
    -webkit-text-fill-color: initial;
  }
  .MuiSelect-icon {
    right: 1px;
  }
}

.stateZipCode.autoPay{
  .stateInput {
    position: relative;

    .errorMsgFormat.autoPay{
      position: absolute;
      bottom: -20px;
    }
  }
}

/* Forget Password */

.forget-pass-radioGroup{
  margin-top: 40px;
}
.css-61q0b2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  width: 571px !important;
}
@media screen and (max-width: 1279.9px) and (min-width: 1024px) {
  .css-61q0b2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    width: 536px !important;
  }
}
@media screen and (max-width: 1023.9px) and (min-width: 768px) {
  .css-61q0b2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    width: 504px !important;
  }
  .forget-pass-multibutton{
    display: flex;
    gap:16px
  }
}
@media screen and (max-width: 767.9px) and (min-width:375px) {
  .css-61q0b2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    width: 345px !important;
  }
  .forget-pass-multibutton{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    margin-top: 40px;
  }

  .forget-pass-multibutton .leftBtn {
    margin: 20px 0px 0px 0px !important;
  }
  .mobileviewAccountInfo{
    margin: 16px 52px 0px 52px;
  }

  .streetinfo{
    // margin-top: 4px;
    p{
      font-size: 12px !important;
      font-weight:400 !important;
      line-height:16px;
      color: $bs-strong-black-100;
    }
  }

  .cityStateinfo{
    p{
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 16px !important;
      color: $bs-strong-black-100;
    }
  }
  .accountNumberSideBar{
    p{
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
    }
  }
}
.streetinfo {
  p {
    margin-bottom: 4px !important;
  }
}
/* account setting Change Password */
.lableForgotCurrentPassLink{
  cursor: pointer;
}
.lableForgotCurrentPass{
  margin-top: 24px !important;
}
/* Multi Account */

.accountList {
  @media screen and (min-width: 1024px) {
      position: absolute;
      overflow: hidden;
      /* box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px; */
      /* Dropdown Menu Shadow */
      box-shadow: 0px 4px 8px 4px rgba(75, 75, 75, 0.24);
  }
  @media screen and (min-width: 768px) {
    position: absolute;
    overflow: hidden;
    top: 36px;
    opacity: 1;
    transform: none;
    transition: opacity 289ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 192ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    right: 0px;
    transform-origin: 0px 0px;
    display: inline-flex;
    width: 268px;
    padding: 4px 8px 24px 8px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 8px 0px 8px 8px;
    background: #F3F3F4;
    box-shadow: 4px 12px 16px 0px rgba(31, 31, 31, 0.16);
  }

  .myaccount-ul {
    text-transform: capitalize;
    padding: 0px;
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 767.9px) {
      margin-bottom:16px;
    }

    li {
      font-family: $font-family-GraphikRegular;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      /* 17.36px */
      letter-spacing: -0.28px;
      padding: 16px 24px;
      width: 100%;
      color: #1F1F1F;
      @media screen and (max-width: 767.9px) {
        padding: 8px 24px;
      }
      &:hover {
        background: #1f1f1fc5;
        border-radius: 8px;
        color: #FCFCFC;
      }

      &:first-child {
        margin-top: 0px;
      }
    }
    li.selected-account{
      background: #1f1f1f;
      border-radius: 8px;
      color: #FCFCFC;

      @media screen and (max-width: 767.9px) {
        background: #F1F1F1;
        color: inherit;
      }
    }
  }
    .noAccountFound {
      @media screen and (max-width: 767.9px) {
        display: none;
      }
      color: #000;
      font-family: $font-family-GraphikRegular;;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20.3px;
      letter-spacing: -0.28px;
      width: 252px;
      & a{
        color: #000;
        margin-left: 2px;
      }
    }
}

.accountListHide {
  display: none;
}

.myaccount-manu {
  @media screen and (min-width: 768px) {
    position: relative;
  }

  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-left: 24px;
}

.myaccountImageIcon {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 0.667px 2.515px 0.667px 2.152px;
  justify-content: center;
  align-items: center;
  margin: 0px -20px -22px;
}

// .menuicon{
//   margin: 58px !important;
// }
.myaccountImageSpan {
  @media screen and (max-width: 767.9px) {
    border-bottom:transparent;
  }
  font-family: $font-family-GraphikRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  /* 19.84px */
  letter-spacing: -0.32px;
  padding: 0px 0px 2px 0px;
  border-bottom: 2px solid #FFC800;
  cursor: pointer;
}

.myaccount-ul {
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 32px;
  text-transform: capitalize;
  /* margin-bottom: 32px; */
}

.muaccountCheckmark {
  margin: 0px 0px 0px -24px;
  padding-right: 5px;
}

.myaccountImageSpan {
 
  font-family: $font-family-GraphikRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  /* 19.84px */
  letter-spacing: -0.32px;

  .arrows {
    background: url("../images/upArrow.png") no-repeat center right;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position-y: 4px;
  }
}

.myaccountImageSpanclose {
  font-family: $font-family-GraphikRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  /* 19.84px */
  letter-spacing: -0.32px;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  // margin-top: 2px;
  &:hover {
    @media screen and (max-width: 767.9px) {
      border-bottom:transparent;
    }
    border-bottom: 2px solid #1F1F1F;
  }

  .arrows {
    background: url("../images/downArrow.png") no-repeat center right;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position-y: 2px;
  }
}

@media screen and (max-width: 767.9px) {
  .myaccountImageSpan {
    // border-bottom: none
  }

  .myaccountImageIcon12 {
    display: none;
  }

  .accountListHide {
    display: none;
  }

}

@media screen and (max-width: 767.9px) {
  .menuicon {
    margin: 0px !important;
  }
}

.wideContainer {
  .accountList {
    button.MuiButtonBase-root {
       width: 238px !important;
      border-radius: 8px;
      background: transparent;
      margin-left: 0px !important;
      max-width: 238px;
      min-width: auto;
      height: 40px;
      font-family: $font-family-GraphikRegular;
      font-weight: 600;

      &:hover {
        background: #000;
        color: #ffffff;
      }

      span {
        width: 190px !important;
      }

      @media screen and (max-width: 768px) {
        max-width: 240px !important;
        width: 240px !important;
      }

    }
  }
}

.myAccountDivider {
  margin-top: 5px;
  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
  @media screen and (max-width: 767.9px) {
  display: none !important;
  }
}
.navDividerMobile  {
  @media screen and (max-width: 767.9px) {
    margin:48px 16px !important;
    }
}
.accountList {
  .mainSection {
    @media screen and (min-width: 768px) {
      display: none;
    }

    @media screen and (max-width: 767.9px) {
      display: none;
    }

    width: 100%;

    .headerSection {
      @media screen and (max-width: 767.9px) {
        display: none;
      }

      display: flex;
      justify-content: space-between;
    }
  }

  hr {
    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }
  // @media screen and (min-width: 768px) and (max-width: 1023.9px) {
  //   width: 100vw;
  //   background: white;
  //   left: 0px;
  //   position: absolute;
  //   top:0px;
  //   padding: 32px 24px;

  //   .myaccountImageSpan{
  //     border-bottom: none;
  //     color: var(--Strong-Black-100, #000);
  //     font-feature-settings: 'clig' off, 'liga' off;
  //     font-family: $font-family-Poppins;
  //     font-size: 14px;
  //     font-style: normal;
  //     font-weight: 500;
  //     line-height: 21px; 
  //   }
  //   .myaccount-ul{
  //     margin-top: 8px;
  //     text-transform: capitalize;
  //   }
  //   .bottomSection{
  //     margin-top: 24px;
  //     .myaccountImageIcon12{
  //       height: 16px;
  //       width: 16px;
  //     }
  //   }
  // }  
}

.mobileviewmyAccountInfo {
  @media screen and (max-width: 767.9px) {
    .myaccount-manu {
      flex-direction: column;
      margin-left: 0px;

      .myaccountImageSpanclose {
        .arrows {
          background-position-y: -2px;
        }
      }

      .myaccountImageSpan {
        .arrows {
          background-position-y: 1px;
        }
      }
    }

    margin: 16px 24px 0px 24px;
  }

  .myaccountImageSpanclose,
  .myaccountImageSpan {
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 150% */
    display: flex;
  }

  .accountList .myaccount-ul li {
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 150% */
  }
}

.cancleIcon {
  @media screen and (min-width: 768px) and (max-width: 1023.9px) {
    position: absolute;
    right: 24px;
    top: 24px
  }
}
/* Reset password page */
.resetMain{
  .resetList{
    margin-top: 16px;
  }
  @media screen and (max-width: 767.9px) {
    div.rememberMe{
      gap:20px
    }
  }

}
/* remove the password reveal iocn for Edge browser*/
::-ms-reveal {
  display: none;
}
.paymentListTermsCondition{
  font-family: Inter, Helvetica, sans-serif !important;
  font-size: 14px !important;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 40px !important;
  padding: 0;
  @media screen and (max-width: 767.9px){
    margin-top: 32px!important;
    padding-left: 16px;
  }
}
/**/
.pInfoEditMode {

  .nameForm.userNickName,
  .nameForm.userName,
  .phoneForm.userPhone,.emailForm.userEmail {
    display: flex;
    gap: 24px;
    margin-top: 24px!important;
    @media screen and (max-width: 1023.9px){
      flex-direction: column;

    }
    .MuiFormControl-root.userName {
      margin-right: 0px;
      @media screen and (min-width: 1024px) {
        width: 303px;

      }
      @media screen and (max-width: 1023.9px){
        width: 376px;

      }
      @media screen and (max-width: 767.9px){
        width: 100%!important;

      }
    }
  }
  .businessForm{
    .MuiFormControl-root.name {
      margin-right: 0px;
      @media screen and (min-width: 1024px) {
        width: 630px;
      }
      @media screen and (max-width: 1023.9px){
        width: 376px;

      }
      @media screen and (max-width: 767.9px){
        width: 100%!important;
      }
    }
  }

  .nameForm.userName {

    .firstName,
    .lastName {
      margin-right: 0px;
      margin-left: 0px;
      @media screen and (min-width: 1024px) {
        width: 232px;

      }

    }
    .firstName{
      @media screen and (max-width: 1023.9px){
        width: 376px;

      }
      @media screen and (max-width: 767.9px){
        width: 100%!important;

      }
    }
    .suffix {
      div.MuiInputBase-root {
        border-style: none;
      }

      input {
        background: #F2F3F3;
        border: 1px solid #646464;
      }

      @media screen and (min-width: 1024px) {
        width: 118px;
      }
    }
  }

  .phoneForm.userPhone,.emailForm.userEmail {
    .leftDiv {
      margin-left: 0px;
      margin-right: 0px;
      .phoneNumber {
        @media screen and (min-width: 1024px) {
          width: 303px;
          margin-right: 0px;
        }
        @media screen and (max-width: 1023.9px){
          width: 376px;

        }
        @media screen and (max-width: 767.9px){
          width: 100%!important;

        }
      }
      @media screen and (max-width: 1023.9px){
        width: 376px;

      }
      @media screen and (max-width: 767.9px){
        width: 100%!important;

      }
    }
    .rightDiv.secondaryPhoneNumber,.secondaryMail.rightDiv{
      margin-left: 0px;
      @media screen and  (max-width: 1023.9px){
        width: 376px;
        margin-top: 0px!important;
      }
      @media screen and (max-width: 767.9px){
        width: 100%!important;

      }
    }
  }
  .addressInfo{
    margin-top: 48px!important;
  }
  // .addressForm {
  //   .leftDiv {
  //     & > p:first-child {
  //       margin-bottom: 4px;
  //     }
  //   }
  // }
  .lastNameSuffix{
    display: flex;
    gap: 24px;
    @media screen  and (max-width: 1023.9px){
      width: 376px;
      align-items: end;
    }
    @media screen and (max-width: 767.9px){
      width: 100%!important;
    
    }
    .lastName{
      @media screen  and (max-width: 1023.9px){
        margin-top: 0px!important;

      }
    }
  }
}
/**/

.homeFooterLinks{
  display: flex;
  align-items: center;
  .vDivider{
    width: 1px;
    height: 12px;
    border: 1px solid black;
    margin-left: 12px;
    margin-right: 12px;
  }
  :last-child{
    display: none;
  }
}
.loginFooterOutWrapper{
  .loginFooterOutcols1{
    .cols1,.cols2{
      p{
        font-family: $font-family-PoppinsMedium;
      }
      p.footerTitles{
        font-family: $font-family-PoppinsSemiBold;
      }
    }
  }
  .loginFooterOutcols2{
    p{
      font-family: $font-family-PoppinsMedium;
    }
  }
}
.mobileDisplayLinks {
  p{
    font-family: $font-family-PoppinsMedium;
    a.mobileLinks{
      font-family: $font-family-PoppinsMedium;
    }
  }
}

.requiredlabel{
  color: var(--Strong-Black-50, #7F7F7F);
  font-size: 12px;
}

// .wideContainer {

  // .accountNumberSideBar {
  //   margin: 0;
  //   padding-left: 44px;
  //   text-align: left;
  //   p {
  //     font-family: $font-family-Poppins;
  //     font-size: 14px;
  //     font-weight: 500;
  //     font-stretch: normal;
  //     /*  padding-top: 5%; */
  //     /* margin-top: 16px; */
  //     font-style: normal;
  //     line-height: 1.42;
  //     letter-spacing: normal;
  //     color: $bs-strong-black-100;
  //   }
  // }

  // .addressSideBar {
  //   text-align: left;
  //   margin: 0;
  //   padding-left: 44px;

  //   p {
  //     font-family: Inter !important;
  //     font-size: 12px;
  //     font-weight: 400 !important;
  //     margin-top: 4px;
  //     font-style: normal;
  //     line-height: 16px;
  //     letter-spacing: normal;
  //     color: $bs-strong-black-100;
  //   }
  // }
// }

@media screen and (max-width: 767.9px) {
  .mobileviewAccountInfo {
    margin: 0px 24px 0px 24px;
  }

  // .streetinfo {
  //   margin-top: 16px;

  //   p {
  //     font-family: $font-family-Inter;
  //     font-size: 12px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 16px;
  //   }
  // }

  .cityStateinfo {
    font-family: $font-family-Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    // margin-top: 4px;
  }

  .accountNumberSideBar {
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      font-family: $font-family-Poppins;
      padding-bottom: 16px;
    }
  }
  .nickname p {
    color: $bs-strong-black-100;
    font-weight: 600 !important;
    // text-transform: capitalize;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px!important;
  }
}
.customRadiusModal {
  .MuiDialog-scrollPaper {
    .MuiDialog-paper {
      border-radius: 24px;
    }
  }
}


.wideContainer {
  .paymentArrangements {
    .title {
      font-family: Poppins;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: 31px;          
    }
    h4 {
      margin-top: 0;
      @media screen and (max-width:767.9px) {
        padding-left: 16px;
        margin-bottom: 32px;
      }
      + .buttonLinks {
        @media screen and (max-width:767.9px) {          
          margin-bottom: 32px;
        }
      }
    }

    .payment-arrangement-cta {
      margin-top: 40px;
      @media screen and (max-width:767.9px) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: 0!important;
      .MuiSelect-select {
        border-radius: 0!important;
        padding: 8px 12px!important;
        border: 1px solid #000000!important;
        .MuiListItemText-root {
          margin: 0!important;
        }
      }
    }

    .paymentDetailsReview {
      gap: 40px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .reviewInstallment {
      gap: 0;
      color: $bs-strong-black-100;
      > div {     
        margin-top: 32px;       
        gap: 0;
        @media screen and (min-width:768px) {
          width:536px;
        }
         > div {
          width: 100%;
          gap:0;
          margin-bottom: 24px;         
        }
         > p {
          margin-bottom: 16px;
          // @media screen and (max-width:767.9px) {
          //   margin-bottom: 8px;
          // }
        }
      }      
    }

    .monthlyTotal {
      font-family: $font-family-Poppins;
      color: $bs-strong-black-100;
    }

    .installPayDetails {
      gap: 0;
      .reviewPayDetails {
        gap: 0;
        @media screen and (min-width:768px) {
          width:536px;
        }  
        > div {
          width: 100%;
          margin-bottom: 24px;
        }   
        > p {
          margin-bottom: 16px;
        }  
      }
    }

    .BackPaymentTitle {
      color: $bs-strong-black-100;     
      text-decoration: underline;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-decoration-line: underline;
      cursor: pointer;

      svg {
        margin-right: 6px;
      }

      @media screen and (max-width: 767.9px) {
        margin-left: 10px;
      }
    }

    .paymentDetails {
      padding: 48px 24px 48px 24px;
      background: #ffffff;
      @media screen and (max-width:767.9px) {
        padding: 48px 16px;
      }

      .title {
        font-family: Poppins;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
      }

      hr {
        margin-top: 15px;
        margin-bottom: 32px;
      }

      .subTitle {
        color: $bs-strong-black-100;              
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .detailsWrapper {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        width: 343px;
        gap: 24px;
        @media screen and (max-width:767.9px) {
          width: 100%;
        }

        .paymentField {
          display: flex;
          justify-content: space-between;
        }    

        .paymentheading {
          font-family: $font-family-Inter;
        }  
      }
    }

    .paymentSection {
      padding: 48px 24px 48px 24px;
      background: #ffffff;
      margin-top: 40px;
      @media screen and (max-width:767.9px) {
        padding: 48px 16px;
        margin-top: 32px;
      }

      .title {
        font-family: Poppins !important;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
      }

      .subTitle {
        color: $bs-strong-black-100;              
        font-family: $font-family-Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 16px;
      }

      hr {
        margin-top: 15px;
        margin-bottom: 32px;
      }

      .dropdownWrapper {       
        @media screen and (min-width:768px) {
          width: 376px;
        }       
        .dropdownPaymentArrangement {
          width: 100%;         
        }
      }

      .infoWrapper {
        display: flex;
        column-gap: 4px;
        margin: 4px 0 16px 0;
        padding-top: 4px;

        .infoDesc {
          color: #646464;
          font-family: Inter !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .required {
        color: #646464;
        font-family: Inter !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .btnWrapper {
        margin-top: 40px;
        display: flex;
        column-gap: 24px;

        button {
          width: 240px;

          &:hover {
            background-color: #000;
            color: #fff;
          }
        }
      }
    }

    .scheduleDetails {
      display: flex;
      padding: 48px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px 24px;
      background: #ffffff;
      @media screen and (max-width:767.9px) {
        padding: 48px 16px;
        // margin-top: 34px!important;
      }

      .mainWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .detailsWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          align-self: stretch;

          .header {
            .title {
              font-family: Poppins;
              font-size: 19px;
              font-style: normal;
              font-weight: 600;
              line-height: 27px;
            }

            hr {
              margin-top: 15px;
              margin-bottom: 0;
            }
          }

          ul {
            margin-bottom: 0;
            padding-left: 26px;
            font-family: $font-family-Inter;
            // @media screen and (max-width:767.9px) {
            //   padding-left: 26px;
            // }
          }

          .installmentDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            @media screen and (max-width:767.9px) {
              width: 100%;             
            }

            .title {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }

            .installmentPaymentDetails {
              display: flex;
              padding-right: 64px;
              align-items: flex-start;
              gap: 24px;
              @media screen and (max-width:767.9px) {
                width:100%;
                padding-right: 0;
                flex-direction: column;
                gap: 24px;
              }

              .section1 {
                display: flex;
                width: 294px;
                // padding-right: 64px;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;   
                @media screen and (max-width:767.9px) {
                  width:100%;
                  padding-right: 0;                               
                }

                .name {
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }

                .value {
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;
                }
                &.withPaymentInput {
                  gap: 4px;
                  input[type="text"] {
                    font-family: $font-family-Inter;
                    border: 1px solid #000;
                    height: 40px;
                    width: 100%;
                    padding: 8px 16px;                    
                  }  
                  .value {
                    margin-top:12px;
                  }               
                }
              }

              .section2 {
                display: flex;
                // padding-right: 64px;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                &.withDate {
                  gap: 0;
                  .value {
                    margin-top:16px;
                  }  
                  .react-datepicker-wrapper {
                    margin-top: 4px;
                  }
                }
                @media screen and (max-width:767.9px) {
                  width:100%;
                  padding-right: 0;                
                }

                .name {
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }

                .value {
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;
                }

                .datePickerInput {
                  // text-align: center;
                  background-image: url("../../assets/images/calenderSvg/calendar.svg"),
                    url("../../assets/images/dropdownCaret/black.svg");
                  background-position: left 16px center, right 12px center;
                  background-repeat: no-repeat, no-repeat;
                  height: 40px;
                  padding-left: 46px;
                  border: 1px solid #000;
                  width: 100%;
                  font-family: $font-family-Inter;
                  @media screen and (min-width:768px) {
                    width: 294px;
                  }
                }
                .react-datepicker-wrapper {
                  width: 100%!important;
                }
                .react-datepicker {
                  @media screen and (min-width:768px) {
                    width: 294px;
                  }                  
                  border: 1px solid #E8E8E8;                 
                  padding: 24px 24px 16px 24px;
                  border-radius: 8px;
                  box-shadow: 0px 4px 8px 4px rgba(75, 75, 75, 0.24);
                }
                div.react-datepicker__week, 
                .react-datepicker__day-names {
                  gap: 0;
                  justify-content: space-between;
                  margin-bottom: 8px !important;
                }
                div.react-datepicker__day,
                div.react-datepicker__day-name {
                  margin-right: 0;
                  margin-bottom: 0;
                }
                div.react-datepicker__day-name { 
                  font-family: $font-family-PoppinsBold;
                }
                .react-datepicker-popper {
                  padding-top: 2px;
                }
                .react-datepicker__navigation {
                  top:24px;
                }
                div.react-datepicker__day--disabled, 
                div.react-datepicker__month-text--disabled, 
                div.react-datepicker__quarter-text--disabled, 
                div.react-datepicker__year-text--disabled {
                  color: #646464!important;
                  font-family: $font-family-Inter;
                }
                .react-datepicker__header,
                .react-datepicker__month {
                  width: 100%;
                }
                .react-datepicker__navigation-icon::before {
                  border-width: 2px 2px 0 0;
                  width: 10px;
                  height: 10px;
                }
                button.react-datepicker__navigation--previous {                 
                  @media screen and (min-width:768px) {
                    left: 11px;
                  }  
                }
                button.react-datepicker__navigation--next {                
                  @media screen and (min-width:768px) {
                    right: 11px;
                  }  
                }               
              }
            }
          }

          .totalBlock {
            font-family: Poppins;
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 31px;
            display: flex;
            gap: 6px;
            @media screen and (max-width:767.9px) {
              flex-direction: column;
            }
          }
        }        
      }
      .convenienceFeeDescription {
        color: #646464;
        display: flex;
        font-family: $font-family-Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
      .convenienceFeeIcon {
        // align-self: flex-start;
        // margin:10px 0 0 16px;
        // margin-left: 16px;
        background: url("../../assets/images/Icon-alert.png") no-repeat;
        min-width: 16px;
      }       
      .requiredText {
        color: #646464;        
        font-size: 12px!important;
        font-style: normal;
        font-weight: 400;
        line-height: 16px!important;
      }

      .paymentMethodWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;      
        align-self: stretch;

        ~ div {
          @media screen and (max-width:767.9px) {
            width: 100%;
            flex-direction: column-reverse;
          }
        }

        .title {
          font-family: Inter !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 32px;
        }

        .dropdownWrapper {
          display: flex;         
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          width: 100%;
          .dropdownPaymentArrangement {
            width: 100%;
          }
          @media screen and (min-width:768px) {
            width: 376px;
          }

          .label {
            font-family: $font-family-Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: $bs-strong-black-100;
          }

          .MuiInputBase-root.MuiOutlinedInput-root .MuiSelect-select {
            padding: 7px 12px !important;
          }
        }

        .termsWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          margin-top: 32px;
          @media screen and (min-width:1280px) {
            margin-bottom: 8px;
          }
          .text {
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: normal;
          }

          .terms {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: normal;
            color: #000000;
            a{
              color: inherit;
              margin-left: 0;
            }
          }
        }
      }

      .addNewPaymentMethod {
        margin-top: 16px!important;
        .addressOne {
          margin-top: 24px!important;
        }
      }

      .primarycheckboxService {
        padding-top: 24px;
      }

      .payment-overlay-cta {
        margin-top: 32px!important;
      }

      .btnWrapper {
        display: flex;
        align-items: flex-start;
        gap: 24px;
      }
    }

    .reviewPayment {
      display: flex;
      padding: 48px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      background: #ffffff;
      @media screen and (min-width:768px) {
        padding: 48px 24px;
      }

      .mainWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;

        .detailsWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          align-self: stretch;

          .header {
            width: 100%;

            .title {
              font-family: Poppins;
              font-size: 19px;
              font-style: normal;
              font-weight: 600;
              line-height: 27px;
            }

            hr {
              margin-top: 15px;
              margin-bottom: 0;
            }
          }

          .paymentDetailsText {
            display: flex;
            flex-direction: column;
            gap: 0;

            .title {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              margin-bottom: 6px;
            }

            .description {
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 15px;
              letter-spacing: normal;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }

          .paymentPlans {
            display: flex;
            width: 536px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;

            .installementsWrapper {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-self: stretch;

              .header {
                display: flex;
                width: 536px;
                align-items: flex-start;
                gap: 24px;

                div {
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  width: 141px;
                }
              }

              .installmentDetailsWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                width: 536px;
                align-self: stretch;

                .installmentDetails {
                  display: flex;
                  width: 536px;
                  align-items: flex-start;
                  gap: 24px;

                  .name {
                    width: 141px;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 21px;
                  }

                  .date {
                    width: 141px;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px
                  }

                  .amountWrapper {
                    width: 141px;
                    display: flex;
                    flex-direction: column;

                    .amount {
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 21px;
                    }

                    .note {
                      font-family: Inter;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 16px;
                    }
                  }
                }
              }
            }

            .totalBlock {
              display: flex;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;

              .text {
                width: 328px;
              }            
            }
          }

        }

        .requiredText {
          color: #7F7F7F;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px
        }

        .paymentDetailsWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          align-self: stretch;

          .header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

            .title {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }

          .contentWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;

            .payments {
              display: flex;
              width: 536px;
              flex-direction: column;
              align-items: flex-start;
              gap: 24px;

              .detailWrapper {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;

                .title {
                  align-self: stretch;
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  flex: 1 0;
                }

                .detail {
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }

                .total {
                  flex: 1 0 0;
                  color: var(--Strong-Black-100, #000);
                
                  /* Subheadings/Subheading Level 3 */
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                }

                .totalAmount {
                  color: var(--Strong-Black-100, #000);
                
                  /* Subheadings/Subheading Level 3 */
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                }
              }

            }

            .bottomNote {
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }

        .btnWrapper {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          @media screen and (max-width:767.9px) {
            flex-direction: column-reverse;
          }
        }
      }

    }
    .addNewPaymentMethod {
      @media screen and (min-width:768px) {        
        .stateZipCode {
          gap: 24px;
          width: 380px;
        }
        .cityInput {
          width: 190px;
        }
        .cityZipcode {
          max-width: 376px;
        }
      }     
    }
  }

  .menuList .MuiDivider-root.MuiDivider-fullWidth {
    margin: 48px 24px;
  }
}

.menuicon {
  display: flex;
  align-items: center;

  .phoneSection {
    display: flex;
    align-items: center;

    span {
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid #1F1F1F;
      }
    }

    span.MuiTypography-root.headerPhone {
      color: #1F1F1F;
      font-family: $font-family-GraphikRegular;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 124%;
      /* 19.84px */
      letter-spacing: -0.32px;
      margin-top: 2px;
    }
  }
}

.learnMore {  
  background: url("../images/addpayment/addpaymentIcon.png") no-repeat center left;
  cursor: pointer;
  .learnText {
    //styleName: Text Links/Medium Default;
    font-family: $font-family-Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    padding-left: 24px;
    text-decoration: underline;
  }
}
.learnMorectl {
  cursor: pointer;
  .learnText {
    //styleName: Text Links/Medium Default;
    font-family: $font-family-Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    margin-left: 8px;
    text-decoration: underline;
  }
}
.broadbandLabelModal {
  .mainTitle {
    font-family: $font-family-Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    margin-bottom: 16px;
  }
  .fiberTitle {
    color: var(--Strong-Black-100, #000);
  

    /* Headings/Heading Level 3 */
    font-family: $font-family-Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 128.571% */
    margin-bottom: 16px;
}
.fiberDescription {
  color: var(--Strong-Black-100, #000);

  /* Body Text/Regular */
  font-family: $font-family-Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 16px;
}
.fiberDescriptionvoice {
  color: var(--Strong-Black-100, #000);
 

  /* Body Text/Regular */
  font-family: $font-family-Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 24px;
}
}



// .customRadiusModal {
//   .MuiDialog-scrollPaper {
//     .MuiDialog-paper {
//       border-radius: 24px;
//     }
//   }
// }

// .componentLink.detailTermNotificationLink:hover {
//   color: #0a58ca !important;
//   cursor: pointer;
// }

.mb-40imp {
  margin-bottom: 40px !important;
}

.font-23imp {
  font-size: 23px !important;
}

.pl-xs-16 {
  @media screen and (max-width: 767.9px) {
    padding-left: 16px;  
  }
}

.non-auth-curvebordermodal {
  padding: 64px 16px 48px;
  @media screen and (min-width:768px) {
    padding: 64px 40px 48px 40px;
  }
  .close-icon-cantainer {
    margin-right: 20px;
    margin-top: 16px;
  }
  .modalHeading {
    font-family: $font-family-Poppins;
    line-height: 36px;
    color: $bs-strong-black-100;
  }
  .modalText {
    color: $bs-strong-black-100;
    letter-spacing: normal;
  }
  .needhelpPaymentArrangement{
    font-size: 19px!important;
    font-weight: 700!important;
    letter-spacing: normal;
    margin-bottom:12px!important;
    font-family: Poppins !important;
    line-height: 27px!important;
    color: #000000;      
}
.learnMorePaymentArrangement {
    color:  #000;
    font-family: Inter!important;
    font-size: 14px!important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px!important; 
    text-decoration-line: underline;
}
}
.non-auth-printLink {
  font-size: 16px;
  color: #4D4D4D;
  margin-left: 6px;
}
.addNewPaymentMethod .termsCheckbox {
  margin-top: 16px!important;
}
.addNewPaymentMethod .termsCheckbox a{
 color: inherit;
}
.BeforeContinueSms {
  margin-top: 32px!important;
  line-height: 24px!important;
  font-size: 16px !important;
}
.paymentMethodWrapper .addNewPaymentMethod .creditCardLabel { 
  color: #000000;
  margin-top: 0!important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: transparent!important;
}

.addaccountButton {
  display: flex;
  gap: 24px;

  @media screen and (max-width: 767.9px) {
    flex-direction: column-reverse;   
  }
}

.wideContainer {
  div.main-container {
    @media screen and (max-width: 767.9px) {
      padding: 88px 0px 80px 0px;
    }

    @media screen and (max-width: 1023.9px) and (min-width: 768px) {
      padding: 88px 36px 96px 36px;
    }

    @media screen and (min-width:1280px) {
      padding: 40px 100px 96px 100px;
    }

    @media screen and (min-width:1440px) {
      .paymentArrangements,
      .notificationBox {
        max-width: 1240px;
        margin: auto;
      }
    }
  }
}

.customRadiusModal {
  .close-icon-cantainer {
    position: absolute;
    right: 16px;
    top: 16px;
    margin: 0;
    padding: 0;
    z-index: 999;
    button {
      width: 32px;
      height: 32px;
    }
  }
}

.loginMain {
  .passwordField {
    color: $bs-strong-black-100;
  }
  .font-reminder-title {
    @media screen and (min-width:768px) {
      font-size: 44px;
      line-height: 53px;
    }
  }
  &.reminder-section {
    @media screen and (min-width:768px) {
      width: 696px;
    }
    .createAccount {
      margin-top: 16px;
      @media screen and (min-width:1280px) {
        margin-top: 24px;
      }
    }
  }
  .font-done-title {
    font-size: 44px!important;
    line-height: 53px!important;
  }
  .form-input-container {
    label {
      position: relative;
      & +.MuiInputBase-root {
        margin-top: 4px;
      }
    }
  }
} 
@media screen and (min-width:768px) and (max-width:1023.9px) {
  .saveChangesBtn {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }
  .drawerInactive {
    .pInfoEditMode {
      .saveChangesBtn {
        flex-direction: row;
        gap: 0;
      }
      .phoneForm,
      .addressForm {
        width: 460px;
      }
    }
  }
}

@media screen and (max-width:767.9px) {
  .saveChangesBtn {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }
}
@media screen and (min-width:1024px) and (max-width:1279.9px){
  .supportTools .cardParent {
    grid-template-columns: 1fr 1fr;
  }
  .drawerInactive {
    .supportTools .cardParent {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.rescheduleAppointmentContainer {
  display: flex;
  flex-direction: column;
  max-width: 629px;
  gap: 48px;
  .tableContainer {
    max-width: 621px;
    gap: 48px;
    align-items: flex-start;
    .min-w-210 {
      min-width: 260px; // for tablet
      @media screen and (min-width:1024px) {
        min-width: 210px; // above tablet
      }
    }
  }
}
.reschedileAppointmentHr {
  margin-top: 19px !important;
}
.timeSlotBtn {
  @media screen and (min-width:767px) and (max-width:1023.9px) {
    max-width: 231px !important;
    min-width: 231px !important;
  }
}

.btnText {
  border: 0px;
  background: none;
}

.notificationClose {
  background: none;
  border: 0px;
}

.clearButton{
  background: none;
  border: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;
}

.MuiDialog-paper .custom-close-btn {
  min-width: 24px;
  color: $bs-strong-black-100;
  span {
    background: transparent;
  }
  &:focus-visible {
    outline: auto;
  }
}